import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useRef, useState } from 'react';

import { toast, ToastContainer } from 'react-toastify'; // імпорт білліотеки повідомлень
import 'react-toastify/dist/ReactToastify.css';     // імпорт білліотеки повідомлень
import PokazBlock from './PokazBlock';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc' 
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const initialValues = {
    rahynok: "",
    pokaznyk1: "",
    pokaznyk2: "",
    pokaznyk3: "",
    phone: "",
    zone: "1",
};

const initialRahunok = {first: '', sec: '', third: '', four: '', five: '', six: '', seven: '', eight: '', nine: '', ten: '', }
const initialPokaz = {first: '', sec: '', third: '', four: '', five: '', six: ''}

const SendPokaz = () => {

///////--правила відправки показнику згідно встановлених періодів--//////////////
const [errPeriod, setErrPeriod] = useState(false);

const clientZone = dayjs.tz.guess(); // отримання часової зони користувача
const timestamp = dayjs().tz(clientZone); // отримання часу форматованого згідно його зони
const tz = "Europe/Kiev";
const formatedDateSent = dayjs(timestamp).tz(tz); //форматування під Kiev часову зону

const startMonthFrom = formatedDateSent.startOf('month'); // початок поточного місяця
const startMonthTo = startMonthFrom.add(3, 'Day'); // кінцева дата з початку місяця

const endMonthFrom = formatedDateSent.endOf('month').subtract(2, 'Day'); // початок кінцевого періоду
const endMonthTo = formatedDateSent.endOf('month'); // кінець поточного місяця

///////////////////////////////////////////////////////////////////////////////

const [values, setValues] = useState(initialValues);
const [rahunokValues, setRahunokValues] = useState(initialRahunok);
const [pokazFirstValues, setFirstPokaz] = useState(initialPokaz);
const [pokazSecondValues, setSecondPokaz] = useState(initialPokaz);
const [pokazThirdValues, setThirdPokaz] = useState(initialPokaz);

// const adress = useRef(null);
const lastPokaz = useRef(null);
const firstZone = useRef(null);
const sendRef = useRef(null);

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

const handleCountChange = (e, data, setData) => {
    const { name, value } = e.target;
    setData({
        ...data,
        [name]: value.length > 1 ? '' : value, 
      });
    changeFocus(e);
}

function setGreenActive (event) {
    const allNum = document.querySelectorAll('.number-form');
    for (let i = 0; i < allNum.length; i++) {
        allNum[i].style['background-color'] = '';
        allNum[i].style['color'] = '#FAFAFA';
    } // очищаємо всі номера не в фокусі

    const parent = event.target.closest('.green-inner'); // знаходимо найближчий батьківський контейнер
    const currentNum = parent.querySelector('.number-form'); // забираємо номер контейнера
    currentNum.style['background-color'] = '#37591f';
    currentNum.style['color'] = '#9BE650';
}

function sendForm (event) {
    event.preventDefault();
    setErrPeriod(false);

    const article = {
        rahynok: Object.values(rahunokValues).reduce((acc, num) => acc + num),
        pokaznyk1 : Object.values(pokazFirstValues).reduce((acc, num) => acc + num),
        pokaznyk2 : Object.values(pokazSecondValues).reduce((acc, num) => acc + num),
        pokaznyk3 : Object.values(pokazThirdValues).reduce((acc, num) => acc + num),
        sender: 'lez',
        phone: values.phone,
    }
    
    const config = {     
        headers: { 
            'content-type': 'application/json',
        }
    }

    // перевірка чи показник подається у вірний період
    if(formatedDateSent.isBetween(startMonthFrom, startMonthTo) || formatedDateSent.isBetween(endMonthFrom, endMonthTo)) {
        axios.post('https://services.asd.company/api/pokaznyky/recive-pokaz', article, config)
        .then((response) => {
            if(response.data === -2) { 
                toast.success("Помилка при відправці показу");
            }

            if(response.data === 3) {
                toast.success("Показник прийнято в обробку!");
            }
        })
        .catch(() => {
            toast.error("Помилка при відправці показу");
            
        }).finally(()=>{
            setValues(initialValues);
            setRahunokValues(initialRahunok);
            setFirstPokaz(initialPokaz);
            setSecondPokaz(initialPokaz);
            setThirdPokaz(initialPokaz);
        })
    }
    else {
        setErrPeriod(true);

        setValues(initialValues);
        setRahunokValues(initialRahunok);
        setFirstPokaz(initialPokaz);
        setSecondPokaz(initialPokaz);
        setThirdPokaz(initialPokaz);

        setTimeout(()=>{
            setErrPeriod(false);
        }, 6000);

        toast.error("Завершено період подачі показу");
    }
};

function changeFocus (e) {

    // if(typeof(e.target.nextSibling?.tagName) === 'undefined'){
    //     lastPokaz.current.focus();
    // }

    if(e.target.nextSibling?.tagName === 'INPUT' && e.target.value.length === 1){
        e.target.nextSibling.focus();
    }

    if(e.target.nextSibling?.tagName === 'A') {
        firstZone.current.focus();
        firstZone.current.checked = true;
    }

    if(e.target === lastPokaz.current) {
        sendRef.current.focus();
    }
}

  return (
    <section className="green">
    <Container>
        <form onSubmit={sendForm} type='submit'>
        <Row>
        <Col md={12}>
                <Row>
                    <Col md={6} className='h2-green'>
                        <h3>Передати показники<br/>
                            лічильника — легко<br/>
                            
                            та швидко!</h3>
                    </Col>
                    <Col md={6} className='nobg-green'>
                        <p></p>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className='header-green'>
            <p>ТОВ «Львівенергозбут» продовжує впроваджувати додаткові сервіси для полегшення  комунікації із своїми клієнтами. Пропонуємо нову форму для передачі показів без входу в Персональний кабінет.</p>
            {errPeriod && <p className='err'>Шановний клієнте! Період подачі показів починається за 2 календарні дні до кінця розрахункового місяця та закінчується на 3 календарний день наступного розрахункового періоду (календарного місяця).</p>}
            </Col>
            <div className="green-inner" onFocus={setGreenActive}>
            <Col md={6} className= "header-green">
                 <div className="form-left d-flex align-items-center"><span className="number-form">01</span> <span className="number-text">Введіть номер особового рахунку</span></div>
            </Col>
            <Col md={6} className= "header-green" >
                <input type="number" className="input1" name='first' value={rahunokValues.first} min={0} max={9} onChange={(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} onFocus = {()=>{setRahunokValues(initialRahunok)}} placeholder="0" required/>
                <input type="number" className="input1" name='sec' value={rahunokValues.sec} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>

                <input type="number" className="input1" name='third' value={rahunokValues.third} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>
                <input type="number" className="input1" name='four' value={rahunokValues.four} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>

                <input type="number" className="input1" name='five' value={rahunokValues.five} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>
                <input type="number" className="input1" name='six' value={rahunokValues.six} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>

                <input type="number" className="input1" name='seven' value={rahunokValues.seven} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>
                <input type="number" className="input1" name='eight' value={rahunokValues.eight} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}} placeholder="0" required/>

                <input type="number" className="input1" name='nine' value={rahunokValues.nine} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}}  placeholder="0" required/>
                <input type="number" className="input1" name='ten' value={rahunokValues.ten} min={0} max={9} onChange = {(e)=> {handleCountChange(e,rahunokValues,setRahunokValues)}}  placeholder="0" required/>
            </Col>
            </div>
            <div className="green-inner" onFocus={setGreenActive}>
            <Col md={6} className= "header-green">
                 <div className="form-left d-flex align-items-center"><span className="number-form">02</span> <span className="number-text">Виберіть кількість зон Вашого лічильника</span></div>
            </Col>
            <Col md={6} className= "header-green" onChange={handleInputChange} name='zone' value={values.zone}>
            
                <input type="radio" name="zone" value={"1"} className="circl" id="btn-check-outlined18" autoComplete="off" ref={firstZone} checked={values.zone === "1"}/>
                <label className="radiobtn" htmlFor="btn-check-outlined18" >1 зона</label> 

                <input type="radio" name="zone" value={"2"} className="circl" id="btn-check-outlined19" autoComplete="off" checked={values.zone === "2"}/>
                <label className="radiobtn" htmlFor="btn-check-outlined19">2 зони</label> 

                <input type="radio" name="zone" value={"3"} className="circl" id="btn-check-outlined20" autoComplete="off" checked={values.zone === "3"}/>
                <label className="radiobtn" htmlFor="btn-check-outlined20">3 зони</label> 

                <Link to="/clients/tarif" className="link">Інформація про зони лічильників <img src='/img/link.svg' alt=''/></Link>
            </Col>
            </div>
            <div className="green-inner" onFocus={setGreenActive}>
            <Col md={6} className= "header-green">
               <div className="form-left d-flex align-items-center height0"><span className="number-form">04</span> <span className="number-text">Введіть Ваш номер телефону</span></div>
            </Col>
            <Col md={6} className= "header-green">
            <InputMask 
                mask='(+380)99 9999 999' 
                onChange={handleInputChange} 
                value={values.phone}
                name='phone'
                placeholder='Номер мобільного телефону*'
                className='form-control'
                required
            >
            </InputMask>
            </Col>
            </div>
            <PokazBlock
                index = {5}
                setGreenActive ={setGreenActive}
                pokazValues = {pokazFirstValues}
                handleCountChange ={handleCountChange}
                setPokaz ={setFirstPokaz}
                initialPokaz ={initialPokaz}
                type={(values.zone === '2') ? 'День' : (values.zone === '3') && 'Пік'}
            />
            {(values.zone === '2' || values.zone === '3')
            &&
            <PokazBlock
                    index = {6}
                    setGreenActive ={setGreenActive}
                    pokazValues = {pokazSecondValues}
                    handleCountChange ={handleCountChange}
                    setPokaz ={setSecondPokaz}
                    initialPokaz ={initialPokaz}
                    type={values.zone === '3' ? 'Напівпік' : 'Ніч'}
                />
            }
            {values.zone === '3'
            &&
            <PokazBlock
                    index = {7}
                    setGreenActive ={setGreenActive}
                    pokazValues = {pokazThirdValues}
                    handleCountChange ={handleCountChange}
                    setPokaz ={setThirdPokaz}
                    initialPokaz ={initialPokaz}
                    type={'Ніч'}
                />
            }
            <div className="green-inner">
            <Col md={6} className= "header-green"></Col>
            <Col md={6} className= "header-green">  
                <button type="submit" className="btn-submit" ref={sendRef} >Надіслати <img src='/img/arrow-right-green.svg' width="20" height="16" alt=''/> </button>                  
            </Col>
            </div>
            </Row>
        </form>
    </Container>
    <ToastContainer /> {/* контейнер для відображення повідомлення */}
   </section>
 )
}

export default SendPokaz